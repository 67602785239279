
import { defineComponent, ref } from "vue";
import base from "@/api/base.js";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

interface Filter {
  status: string;
  code: string;
}

export default defineComponent({
  name: "schedules",
  components: { VPagination },

  data() {
    return {
      schedules: [],
      brands: [],
      stores: [],
      searchcode: "cmp_nm",
      searchdata: "",
      page: 1,
      brand_nm: "",
      store_nm: "",
      startDate: "",
      endDate: "",
      check_ver_ty: "",
      check_ver_nm: "",
    };
  },
  created() {
    this.getListData();
    base
      .getBrandList()
      .then((res) => {
        console.log(res);
        this.brands = res.data;
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        const value = Object.values(err.response.data);
        alert(value);
      });
    base
      .getStoreList()
      .then((res) => {
        console.log(res);
        this.stores = res.data;
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        const value = Object.values(err.response.data);
        alert(value);
      });
  },
  methods: {
    getSearchResult() {
      this.page = 1;
      this.getListData();
    },
    getListData() {
      const param = {
        page: this.page,
        brand_nm: this.brand_nm,
        store_nm: this.store_nm,
        start_date: this.startDate,
        end_date: this.endDate,
        check_ver_nm: this.check_ver_nm,
        check_ver_ty: this.check_ver_ty,
      };
      base
        .getScheduleList(param)
        .then((res) => {
          console.log(res);
          this.schedules = res.data;
        })
        .catch((err) => {
          console.log(err);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    fnView(id) {
      console.log(id);
      sessionStorage.setItem("id", id);
      this.$router.push({
        name: "qsc-check",
        params: { id: id },
      });
    },

    fnViewOms(id) {
      console.log("oms qsc id", id);
      sessionStorage.setItem("id", id);
      this.$router.push({
        name: "qsc-check-self",
        params: { id: id },
      });
    },
  },
  setup() {
    const data = ref<Filter>({
      status: "1",
      code: "1",
    });

    return {
      data,
    };
  },
});
